import { lazy } from "react";
import { roles } from "../helpers/permissions";
import { urls } from "./urls";

export const routes = [
    {
        path: urls.home(),
        exact: true,
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
        component: lazy(() => import("components/Devices"))
    },
    // {
    //     path: "/devices",
    //     exact: true,
    //     roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
    //     component: lazy(() => import("components/Devices"))
    // },
    {
        path: urls.device(),
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
        component: lazy(() => import("components/Device"))
    },
    {
        path: urls.unregDevices(),
        exact: true,
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/UnregisteredDevices"))
    },
    {
        path: urls.users(),
        exact: true,
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Users"))
    },
    {
        path: urls.createUser(),
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Users/AddUser"))
    },
    {
        path: urls.userEdit(),
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
        component: lazy(() => import("components/Users/EditUser"))
    },
    {
        path: urls.changePassword(),
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.DATA_PROVIDER, roles.OPERATOR],
        component: lazy(() => import("components/Users/ChangePassword"))
    },
    {
        path: urls.clients(),
        exact: true,
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.OPERATOR],
        component: lazy(() => import("components/Clients"))
    },
    {
        path: urls.client(),
        roles: [roles.SYS_ADMIN, roles.ADMIN, roles.OPERATOR],
        component: lazy(() => import("components/Client"))
    },
    {
        path: urls.settingClients(),
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/ClientsTypes"))
    },
    {
        path: urls.settingModifications(),
        exact: true,
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Modifications"))
    },
    {
        path: urls.settingEditModification(),
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Modifications/EditModification"))
    },
    {
        path: urls.settingAddModification(),
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Modifications/AddModification"))
    },
    {
        path: urls.settingUnits(),
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Units"))
    },
    {
        path: urls.settingManufacturers(),
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/Settings/Manufacturers"))
    },
    {
        path: urls.ambusDevices(),
        exact: true,
        roles: [roles.SYS_ADMIN],
        component: lazy(() => import("components/AmbusDevices"))
    }
];
