import React from "react";
import { Route, Redirect } from "react-router-dom";
import tokenService from "services/token.service";

export const PrivateRoute = ({ component, roles, ...rest }) => {
    const renderRoute = (routeProps) => {
        const currentUser = tokenService.parseJWTToken();
        if (!currentUser || !currentUser.sub || !currentUser.groups) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: "/login", state: { from: routeProps.location } }} />;
        }

        if (roles && !isHasPermission(currentUser.groups, roles)) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: "/" }} />;
        }

        return React.createElement(component, routeProps);
    };

    return <Route {...rest} render={renderRoute} />;
};

function isHasPermission(userGroups, allowedGroups) {
    return userGroups.some((userRole) => allowedGroups.includes(userRole));
}
