export const urls = {
    home: () => "/",
    device: (id = ":id") => `/devices/${id}`,
    unregDevices: () => "/unregistered-devices",
    users: () => "/users",
    createUser: () => "/users/add",
    userEdit: (login = ":login") => `/user/edit/${login}`,
    changePassword: () => "/user/change-password",
    clients: () => "/clients",
    client: (id = ":id") => `/clients/${id}`,
    settings: () => "/settings",
    settingClients: () => "/settings/clients",
    settingModifications: () => "/settings/modifications",
    settingEditModification: (id = ":id") => `/settings/modifications/edit/${id}`,
    settingAddModification: () => "/settings/modifications/add",
    settingUnits: () => "/settings/units",
    settingManufacturers: () => "/settings/manufacturers",
    ambusDevices: () => "/ambus-devices",
    login: () => "/login"
};
