import { theme } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as Logo } from "../../assets/images/logo-company.svg";
import { useConfig } from "../../hooks/useConfig";
import { themeTypes } from "../../helpers/consts";

export const LogoCompany = ({ fontSize }) => {
    const { token } = theme.useToken();
    const { currentTheme } = useConfig();

    return (
        <Icon
            component={Logo}
            style={{
                color: currentTheme === themeTypes.LIGHT ? token.colorPrimary : "#fff",
                fontSize
            }}
        />
    );
};
