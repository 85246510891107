import { $httpPapi, $http } from "./http.service";

export async function getClient(client_id) {
    const res = await $httpPapi.get(`/clients/client/${client_id}`);
    return res.data;
}

export async function getClients() {
    return await $httpPapi.get("/clients");
}

export async function getClientTree(client_id) {
    const url = `/clients/client/${client_id}/tree`;
    const res = await $httpPapi.get(url);
    return res.data;
}

export async function getClientsTree() {
    return await $httpPapi.get("/clients/tree");
}

export async function addClientType(clientType) {
    return await $httpPapi.post(`/clients/types`, clientType);
}
export async function updateExistType(type) {
    return await $httpPapi.put(`/clients/types`, type);
}
export async function removeType(id) {
    return await $httpPapi.delete(`/clients/types?type_id=${id}`);
}

export async function updateGroup(group) {
    return await $http.patch("/client-group", group);
}

export async function deleteGroup(id) {
    let config = {
        data: {
            id: id
        }
    };
    return await $http.delete("client-group", config);
}

export async function getClientUsers(client_id) {
    const res = await $httpPapi.get(`/clients/client/${client_id}/users`);
    return res.data;
}

export async function getClientRoles() {
    const res = await $httpPapi.get("/clients/roles");
    return res.data;
}

export async function deleteClientUser({ clientId, userId }) {
    await $httpPapi.delete(`/clients/client/${clientId}/users/${userId}`);
    return userId;
}

export async function addClientUser(data) {
    return await $httpPapi.post(`/clients/client/${data["client"]}/users`, data);
}

export async function getPoints(clientId) {
    return await $httpPapi.get(`/clients/client/${clientId}/points`);
}

export async function addPoint(data) {
    const res = await $httpPapi.post(`/clients/client/${data.item_id}/points`, data);
    return res.data;
}

export async function getPointDevices(pointId) {
    const res = await $httpPapi.get(`/clients/client/${pointId}/points/devices`);
    return res.data;
}

export async function addPointDevice(data) {
    const res = await $httpPapi.post(`/clients/client/${data.object_id}/points/devices`, data);
    return res.data;
}

export async function removePointDevice(pointId, deviceId) {
    await $httpPapi.get(`/clients/client/${pointId}/points/devices/${deviceId}/unlink`);
    return { pointId, deviceId };
}

export async function deletePoint(point_id) {
    await $httpPapi.delete(`/clients/client/${point_id}/objects`);
    return point_id;
}

export async function addClient(client_info) {
    return await $http.post("/client", client_info);
}

export async function updateClient(data) {
    const res = await $httpPapi.put(`/clients/client/${data["id"]}`, data);
    return res.data;
}

export async function deleteClient(clientId) {
    return await $httpPapi.delete(`/clients/client_id/${clientId}`);
}

export async function updateExistClient(client_info) {
    return await $http.patch("/client", client_info);
}

export async function updateClientUser(data) {
    return await $http.patch("/client-relation", data);
}

export async function addObject(data) {
    return await $http.post("/object", data);
}

export async function deleteObject(objectID) {
    return await $http.delete("/object", {
        data: {
            id: parseInt(objectID)
        }
    });
}

export async function updateObject(data) {
    const res = await $http.patch("/object", data);
    return { ...data, success: res.data.success };
}

export async function getClientTypes() {
    return await $httpPapi.get("/clients/types");
}

export async function getUnregDevicesByClientId(clientId) {
    const res = await $httpPapi.get(`/clients/client/${clientId}/points/devices/storage`);
    return res.data;
}

export async function getAmbusRoutersByClientId(clientId) {
    const res = await $httpPapi.get(`/clients/client/${clientId}/ambus_routers`);
    return res.data;
}

export async function createAmbusRouter(clientId, body) {
    const res = await $httpPapi.post(`/clients/client/${clientId}/ambus_routers`, body);
    return res.data;
}

export async function editAmbusRouter(clientId, body) {
    const res = await $httpPapi.put(`/clients/client/${clientId}/ambus_routers`, body);
    return res.data;
}

export async function deleteAmbusRouter(clientId, body) {
    const res = await $httpPapi.delete(`/clients/client_id/${clientId}/ambus_routers`, {
        data: body
    });
    return res.data;
}

export async function getAmbusRouterDevices(clientId, router_in) {
    const res = await $httpPapi.get(`/clients/client/${clientId}/ambus_devices`, {
        params: { router_in }
    });
    return res.data;
}

export async function getAmbusRouters() {
    const res = await $httpPapi.get(`/ambus_route/all_client_gateway`);
    return res.data;
}

export async function getAmbusRouterScanStatus(clientId, router_in) {
    const res = await $httpPapi.get(`/clients/client/${clientId}/status_scan_ambus_network`, {
        params: { router_in }
    });
    return res.data;
}

export async function startScanAmbusRouter(clientId, router_in) {
    const res = await $httpPapi.get(`clients/client/${clientId}/scan_ambus_network`, {
        params: { router_in }
    });
    return res.data;
}
