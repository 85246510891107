import { Modal, Typography, Tabs } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router-dom";
import { useStores } from "hooks/useStores";
import { SelectCompanyForm } from "./SelectCompanyForm";
import { LoginAuthForm } from "./LoginAuthForm";
import { LogoCompany } from "components/ui/LogoCompany";
import { SELECTED_COMPANY_STORAGE, SETTINGS_STORAGE } from "helpers/consts";
import { urls } from "routes/urls";

const tabKeys = {
    AUTH: "auth"
};

export const LoginModal = observer(({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const { userStore, settingsStore } = useStores();
    const history = useHistory();
    const location = useLocation();
    const [activeTabKey, setActiveTabKey] = useState(tabKeys.AUTH);

    const finishAuthorization = ({ username, password }) => {
        userStore.clearUserError();
        userStore.fetchUserAuth(username, password, (userClients) => {
            if (!userClients?.length) {
                onSelectCompany({});
            }
        });
    };

    const checkSelectedUserCompany = (company) => {
        const storageCompany = localStorage.getItem(SELECTED_COMPANY_STORAGE);

        if (storageCompany !== String(company)) {
            localStorage.setItem(SELECTED_COMPANY_STORAGE, company);
            localStorage.removeItem(SETTINGS_STORAGE);
            settingsStore.clearDevicesTree();
        }
    };

    const updateUserTokenWithCompany = (company) => {
        const currentCompany = userStore.UserClients.find((client) => client.client_id === company);
        const role = currentCompany.roles[0].role;
        return userStore.fetchUpgradeUserToken(company, role);
    };

    const onSelectCompany = async ({ company }) => {
        if (company) {
            const isSuccess = await updateUserTokenWithCompany(company);
            if (!isSuccess) {
                return;
            }
        }

        checkSelectedUserCompany(company);

        const path = location.state ? location.state.from : urls.home();
        history.push(path);
    };

    const items = [
        {
            key: tabKeys.AUTH,
            label: t("Authorization"),
            children: !userStore.user ? (
                <LoginAuthForm
                    errorMessage={userStore.userError}
                    onSubmit={finishAuthorization}
                    clearError={userStore.clearUserError}
                    isLoading={userStore.loading}
                />
            ) : (
                <SelectCompanyForm
                    errorMessage={userStore.userError}
                    onSubmit={onSelectCompany}
                    clearError={userStore.clearUserError}
                    clients={userStore.UserClients}
                    isLoading={userStore.loading}
                />
            )
        }
    ];

    return (
        <Modal open={isOpen} footer={null} onCancel={onClose} destroyOnClose={true}>
            <div className='d-flex flex-column align-center'>
                <LogoCompany fontSize={80} />
                <Typography.Paragraph type='secondary'>
                    {t("control system for your devices")}
                </Typography.Paragraph>
            </div>

            <Tabs items={items} activeKey={activeTabKey} onChange={setActiveTabKey} size='large' />
        </Modal>
    );
});
