import { googleAnalyticsConfig } from "../config";

export const THEME_STORAGE_KEY = "theme";
export const LANG_STORAGE_KEY = "i18nextLng";

export const themeTypes = {
    LIGHT: "light",
    DARK: "dark"
};

const setup = (...args) => {
    if (process.env.NODE_ENV !== "production") {
        return;
    }
    if (!window.gtag) {
        return;
    }
    window.gtag(...args);
};

export const track = {
    pageview: (props) => {
        setup("config", googleAnalyticsConfig, props);
    },
    event: (type, props) => {
        setup("event", type, props);
    }
};

export const CHARTS_COLORS = [
    "#263238",
    "#E74C3C",
    "#0E4DA4",
    "#1BB55C",
    "#E71561",
    "#FFBB12",
    "#FF161F",
    "#143960",
    "#67B930",
    "#009688",
    "#FF5722",
    "#9C27B0"
];

export const SETTINGS_STORAGE = "settings";

export const SELECTED_COMPANY_STORAGE = "selected_company";

export const routerFieldKeys = {
    SERIAL_NUMBER: "serial_number",
    IP: "ip",
    PORT: "port",
    START_POLLING_NETWORK: "start_polling",
    POLLING_PERIOD_NETWORK: "polling_period",
    SCAN_TIME: "scan_time",
    START_POLLING_COUNTERS: "read_meters_start",
    POLLING_PERIOD_COUNTERS: "read_meters_period"
};

export const routerFieldLabels = {
    [routerFieldKeys.SERIAL_NUMBER]: "Serial number",
    [routerFieldKeys.IP]: "IP address",
    [routerFieldKeys.PORT]: "Port",
    [routerFieldKeys.START_POLLING_NETWORK]: "Polling time",
    [routerFieldKeys.POLLING_PERIOD_NETWORK]: "Polling interval",
    [routerFieldKeys.SCAN_TIME]: "Last scan",
    [routerFieldKeys.START_POLLING_COUNTERS]: "Start of surveying counters",
    [routerFieldKeys.POLLING_PERIOD_COUNTERS]: "Counter survey period"
};

export const scanStatuses = {
    START: "start_scan",
    PROCESSING: "scanning",
    COMPLEATED: "scan_completed",
    ERROR: "scan_aborted",
    SCAN_NOT_EXIST: "scan_does_not_exist"
};
