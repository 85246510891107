import { makeAutoObservable } from "mobx";
import * as mobx from "mobx";
import { saveAs } from "file-saver";
import * as deviceApi from "../services/device.service";

class DeviceStore {
    _modifications = [];
    _single_modification = {};
    _single_device = null;
    _pivot_table = [];
    _unit_types = [];
    _device_values = [];
    loading = false;
    error = "";

    constructor() {
        makeAutoObservable(this);
    }

    get unit_types() {
        return mobx.toJS(this._unit_types);
    }

    get device() {
        return mobx.toJS(this._single_device);
    }

    get modifications() {
        return this._modifications;
    }

    fetchDeviceModifications = () => {
        deviceApi.getModifications().then(({ modifications, status }) => {
            if (status === 200) {
                this._modifications = modifications;
            }
        });
    };

    get modification() {
        return mobx.toJS(this._single_modification);
    }

    fetchDeviceModification = (id) => {
        this.loading = true;
        deviceApi
            .getModification(id)
            .then((r) => {
                if (r.status === 200) {
                    this._single_modification = r.modification;
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                this.loading = false;
            });
    };

    createModification = async (payload) => {
        try {
            const data = await deviceApi.createModification(payload);
            if (data.success) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    };

    updateDeviceModification = (payload, onCallback) => {
        deviceApi
            .updateModification(payload)
            .then((data) => {
                if (data.success) {
                    if (onCallback) {
                        onCallback(data);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    deleteDeviceModification = (id, callback) => {
        deviceApi
            .deleteModification(id)
            .then(() => {
                this._modifications = this._modifications.filter((item) => item.id !== id);
                if (callback) callback();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    resetDeviceData() {
        this._single_device = null;
        this._device_values = [];
        this._pivot_table = [];
    }

    getDeviceInfoById = async (id) => {
        this.loading = true;
        try {
            const response = await deviceApi.getDevice(id);
            if (response.status === 200) {
                const { device, ...clientInfo } = response.data;
                this._single_device = Object.assign(device, clientInfo);
            }
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    fetchDeviceDataTypes = () => {
        deviceApi.getDatatypes().then((r) => {
            if (r.status === 200) {
                this._unit_types = r.data;
            }
        });
    };

    addNewDataUnit = (values, onCallback) => {
        deviceApi
            .addDatatype(values)
            .then((unitType) => {
                this._unit_types = [unitType, ...this._unit_types];
                if (onCallback) {
                    onCallback();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    updateDataUnit = (values, onCallback) => {
        deviceApi
            .updateDatatype(values)
            .then((updatedUnitType) => {
                this._unit_types = this._unit_types.map((unitType) => {
                    if (unitType.id === updatedUnitType.id) {
                        return updatedUnitType;
                    }
                    return unitType;
                });
                if (onCallback) {
                    onCallback();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    deleteDataUnit = (id, onCallback) => {
        deviceApi
            .deleteDatatype(id)
            .then(() => {
                this._unit_types = this._unit_types.filter((unitType) => unitType.id !== id);
                if (onCallback) {
                    onCallback();
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    get pivot_table() {
        return mobx.toJS(this._pivot_table);
    }

    get device_data_values() {
        return mobx.toJS(this._device_values);
    }

    getDeviceData = (values) => {
        return deviceApi
            .getDeviceProperties(values)
            .then((r) => {
                if (r.status === 200) {
                    this._device_values = r.data["values"];
                    this._pivot_table = r.data["summary"];
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    getReports = (values, callback) => {
        this.loading = true;
        deviceApi
            .getReports(values)
            .then((r) => {
                if (r.status === 200) {
                    // Log somewhat to show that the browser actually exposes the custom HTTP header
                    const fileNameHeader = "Content-Disposition";
                    const suggestedFileName = r.headers[fileNameHeader];
                    const effectiveFileName =
                        suggestedFileName === undefined ? "reports.zip" : suggestedFileName;
                    console.log(
                        `Received header [${fileNameHeader}]: ${suggestedFileName}, effective fileName: ${effectiveFileName}`
                    );

                    // Let the user save the file.
                    saveAs(r.data, effectiveFileName);
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                this.loading = false;
            });
    };
}
export default new DeviceStore();
