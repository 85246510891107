import { Menu, Space } from "antd";
import Icon, {
    ApartmentOutlined,
    AppstoreOutlined,
    ProductOutlined,
    SettingOutlined,
    TeamOutlined
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { LangWidget } from "./LangWidget";
import { ProfileDropdown } from "./ProfileDropdown";
import { LogoCompany } from "../ui/LogoCompany";
import { ThemeWidget } from "./ThemeWidget";
import { ReactComponent as RouterIcon } from "../../assets/images/HugeiconsRouter.svg";
import { urls } from "routes/urls";
import { useStores } from "hooks/useStores";
import { roles } from "helpers/permissions";

export const Navbar = observer(({ hideMenu = false }) => {
    const { userStore } = useStores();
    const { t } = useTranslation();
    const location = useLocation();
    const selectedMenuItem = location.pathname.split("/").slice(0, 2).join("/");
    const userRole = userStore.UserRole;

    const getMenuItems = useMemo(() => {
        const menu_items = [
            {
                label: <Link to={urls.home()}>{t("devices")}</Link>,
                key: urls.home(),
                isPrivate: false,
                icon: <AppstoreOutlined />
            },
            {
                label: <Link to={urls.clients()}>{t("clients")}</Link>,
                key: urls.clients(),
                isPrivate: false,
                icon: <ApartmentOutlined />
            },
            {
                label: <Link to={urls.users()}>{t("users")}</Link>,
                key: urls.users(),
                isPrivate: userRole !== roles.SYS_ADMIN,
                icon: <TeamOutlined />
            },
            {
                label: <Link to={urls.unregDevices()}>{t("devices_without_registration")}</Link>,
                key: urls.unregDevices(),
                isPrivate: userRole !== roles.SYS_ADMIN,
                icon: <ProductOutlined />
            },
            {
                label: <Link to={urls.ambusDevices()}>{t("Routers")}</Link>,
                key: urls.ambusDevices(),
                isPrivate: userRole !== roles.SYS_ADMIN,
                icon: <Icon component={RouterIcon} />
            },
            {
                label: t("settings"),
                key: urls.settings(),
                isPrivate: userRole !== roles.SYS_ADMIN,
                icon: <SettingOutlined />,
                children: [
                    {
                        label: <Link to={urls.settingClients()}>{t("types_customers")}</Link>,
                        key: urls.settingClients()
                    },
                    {
                        label: (
                            <Link to={urls.settingModifications()}>
                                {t("device_modifications")}
                            </Link>
                        ),
                        key: urls.settingModifications()
                    },
                    {
                        label: <Link to={urls.settingUnits()}>{t("types_units_measurement")}</Link>,
                        key: urls.settingUnits()
                    },
                    {
                        label: (
                            <Link to={urls.settingManufacturers()}>
                                {t("manufacturers_devices")}
                            </Link>
                        ),
                        key: urls.settingManufacturers()
                    }
                ]
            }
        ];
        return menu_items.map(({ isPrivate, ...other }) => {
            if (isPrivate) return null;
            return { ...other };
        });
    }, [userRole, t]);

    return (
        <div className='header__wrap container'>
            <div className='logo'>
                <a href='/'>
                    <LogoCompany fontSize={60} />
                </a>
            </div>
            {userRole && !hideMenu && (
                <>
                    <Menu
                        className='main-navbar'
                        mode='horizontal'
                        selectedKeys={[selectedMenuItem, location.pathname]}
                        items={getMenuItems}
                        style={{
                            borderBottom: "none",
                            flex: 1,
                            minWidth: 0
                        }}
                        triggerSubMenuAction='click'
                    />
                    <Space size='middle'>
                        <ThemeWidget />
                        <LangWidget />
                        <ProfileDropdown user={userStore.user} />
                    </Space>
                </>
            )}
        </div>
    );
});
